import { FC } from "react";
import { Button, ModalComponent } from "../../common";
import * as S from "./application.styled";

interface ISuccessApplication {
  close: () => void;
  title: string;
}

export const SuccessApplication: FC<ISuccessApplication> = ({ close, title}) => {
  return (
    <ModalComponent title={title}>
      <S.SuccessContent>
        <S.Paragraph>Thank you for your application. Please check your Inbox, Spam or Junk folder for the invite.</S.Paragraph>
        <S.Paragraph>See you at the event.</S.Paragraph>
        <Button handleClick={close}>Close</Button>
      </S.SuccessContent>
    </ModalComponent>
  )
}

export default SuccessApplication;