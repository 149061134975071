import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(217, 19, 30, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalContent = styled.div`
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  padding: 2rem 3rem;
  border-radius: 0.25rem;
  position: relative;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #d9131e;
  cursor: pointer;
  width: fit-content;
  font-family: sans-serif;
  font-size: 1.5rem;

  &:hover {
    opacity: 0.85;
  }
`;

export const ModalTitle = styled.h3`
  text-align: center;
  margin-bottom: 1rem;
  color: #d9131e;
`;