import { FC, useState } from "react";
import QrReader from "react-qr-reader";
import { ModalComponent } from "../../common";
import { UnknownType } from "../../utils";
import * as S from "./scanner.styled";

interface IResendQRCode {
  close: () => void;
  title: string;
  setScanData: React.Dispatch<React.SetStateAction<string | null>>;
}

const ScanQRCode: FC<IResendQRCode> = ({ close, title, setScanData }) => {
  const [dataError, setDataError] = useState<null | string>(null);
  
  const handleScan = (scanRes: string | null): void => {
    if (scanRes) setScanData(scanRes);
  };
  const handleError = (err: UnknownType): void => {
    setDataError("Unable to scan code. Try again.");
    console.error(err, dataError);
  };

  const handleClose = (): void => {
    close();
    window.location.reload();
  }

  return (
    <ModalComponent title={title} close={handleClose}>
      <S.ModalForm>
        <QrReader
          onScan={handleScan}
          onError={handleError}
          facingMode="environment"
          style={{ minWidth: "275px", maxWidth: "500px" }}
      />
      </S.ModalForm>
    </ModalComponent>
  )
}

export default ScanQRCode;