import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/form-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/form-bg-mobile.jpg';

export const ApplicationBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
`;

export const ApplicationContent = styled.div<{ isMobile: boolean }>`
  min-width: 275px;
  max-width: 65vw;
  box-shadow: 5px 10px 50px 15px rgba(217, 19, 30, 0.75);
  height: fit-content;
  transform: ${(props): StyledProps => props.isMobile ? "translateY(30%)" : "translateY(20%)"};
  background-color: #ffffff;
  padding-top: 1rem;
  color: #d9131e;
  overflow: hidden;
  margin: auto;
`;

export const ApplicationHeader = styled.h2`
  text-align: center;
  font-family: "BudweiserSerif Regular";
  font-size: 3rem;
  margin-bottom: 1rem;
`;

export const ApplicationForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 3rem;
  background-color: #eeeeee;
`;

export const ToastWrapper = styled.div`
  display: block;
`;

export const ToastTitle = styled.h2`
  font-size: 1.5rem;
`;

export const ToastMessage = styled.h2`
  font-size: 1rem;
`;

export const Terms = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const AppCheckBox = styled.input`
  border: 1px solid #d9131e;
  width: 0rem;
  visibility: hidden;
`;

export const BoxMark = styled.div<{$toggleCheck: boolean}>`
  height: 1rem;
  width: 1rem;
  background-color: ${(props): StyledProps => props.$toggleCheck ? "#d9131e" : "transparent"};
  border: ${(props): StyledProps => props.$toggleCheck ? "none" : "1px solid #d9131e"};;
`;

export const CheckWrapper = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const Statement = styled.p`
  width: fit-content;
  height: fit-content;
  font-size: 0.8rem;
`;

export const TermSpan = styled.span`
  color: rgba(217, 19, 30, 0.5);
  cursor: pointer;
`;

export const TermsError = styled.p`
  width: fit-content;
  height: fit-content;
  font-size: 0.8rem;
  color: #000000;
`;

// Terms and Condition
export const TermWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: #ffffff;
  color: #d9131e;
  padding: 1rem 2rem;
  overflow-y: scroll;
`;

export const TermsHeader = styled.h3`
  height: fit-content;
  width: fit-content;
`;

export const Paragraph = styled.p`
  height: fit-content;
  text-align: justify;
  margin-top: 0.5rem;
`;

export const Section = styled.div`
  height: fit-content;
  margin-top: 1rem;
  margin-bottom: 0.5rem; 
`;

export const SubHeader = styled.h5`
  text-transform: capitalize;
  height: fit-content;
  margin-bottom: 0.75rem;
`;

export const ListWrapper = styled.ol`
  counter-reset: item;
`;

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
  counter-increment: item;
  &::before {
    content: counters(item, ".") ". ";
  }

  & ol {
    counter-reset: item;
  }
`;

export const CloseBtn = styled.button`
  height: fit-content;
  padding: 0.5rem 1rem;
  background-color: #d9131e;
  color: #ffffff;
  cursor: pointer;
`;

// Success Modal
export const SuccessContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 275px;
  color: #d9131e;
`;
