import { Link } from "react-router-dom";
import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/welcome-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/welcome-bg-mobile.jpg';

export const NoAccessBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
`;

export const NoAccessContent = styled.div<{ isMobile: boolean }>`
  width: fit-content;
  height: fit-content;
  transform: ${(props): StyledProps => props.isMobile ? "translateY(40%)" : "translateY(60%)"};
  color: #ffffff;
  margin: auto;
`;

export const Rectangle = styled.div`
  width: fit-content;
  background-color: #ffffff;
  padding: 2rem 2rem;
  height: fit-content;
  color: #d9131e;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
`;

export const HeadingTitle = styled.h1`
  font-size: 2rem;
  text-align: center;
`;

export const LinkTag = styled(Link)`
  text-decoration: none;
  color: #d9131e;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
`;