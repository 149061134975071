import { FC, useEffect, useState } from 'react';
import { getIsMobile } from '../../utils/misc';
import * as S from "./noAccess.styled";

interface INoAccess {
  to?: string;
  content?: string;
}

export const NoAccessComponent: FC<INoAccess> = ({to = "/", content = "Go Home"}) => {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <S.NoAccessBackground isMobile={isMobile}>
      <S.NoAccessContent isMobile={isMobile}>
        <S.Rectangle>
          <S.HeadingTitle>Access denied at this time</S.HeadingTitle>
          <S.LinkTag to={to}>{content}</S.LinkTag>
        </S.Rectangle>
      </S.NoAccessContent>
    </S.NoAccessBackground>
  );
}

export default NoAccessComponent;