import styled, { keyframes } from "styled-components";

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const SubmitButton = styled.button`
  background-color: #d9131e;
  color: #ffffff;
  font-size: 1.25rem;
  padding: 0.75rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    background-color: rgba(217, 19, 30, 0.25);
  }

  & svg {
    transition: opacity 200ms;
    animation: ${rotate} 1s linear;
    animation-iteration-count: infinite;
  }
`;

export const SpinnerWrapper = styled.div`
  width: 1rem;
  margin-top: 0.2rem;
`;