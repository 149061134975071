import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Fragment, FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner } from "../../common";
import { getIsMobile, handleErrResponse } from "../../utils";
import {
  useAppSelector,
  useAppDispatch,
  useCloseRegistrationMutation,
  useOpenRegistrationMutation,
  setToggleRegistration,
  useAdminDashboardQuery,
  setAdmin,
  setCanRegister,
  setLogout,
  SetToast,
  initialState,
} from "../../redux";
import ResendQRCode from "./sendQRCode";
import UpdateAvailability from "./updateAvailability";
import { downloadGuestFile } from "./downloads";
import * as S from "./admin.styled";

export const AdminComponent: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { admin } = useAppSelector((state) => state.appstate);
  const [isMobile, setIsMobile] = useState(getIsMobile());

  const {data, isError, isLoading} = useAdminDashboardQuery();
  const [
    closeApplication,
    {data: closeData, isLoading: closeIsLoading, error: closeError},
  ] = useCloseRegistrationMutation();
  const [
    openApplication,
    {data: openData, isLoading: openIsLoading, error: openError},
  ] = useOpenRegistrationMutation();

  const [isNewAvail, setIsNewAvail] = useState(false);
  const [showAvailModal, setShowAvailModal] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);

  const closeAvailHandler = (): void => setShowAvailModal(false);
  const closeCodeHandler = (): void => setShowCodeModal(false);

  const handleApplicationStatus = (): void => {
    if (admin.closed && admin.availableSlot > 0) {
      openApplication();
    } else if (!admin.closed) {
      closeApplication();
    }
  };

  const handleLogout = (): void => {
    dispatch(setLogout(initialState));
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN as string);
    navigate("/login");
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (data && !isError && !isLoading) {
      const canRegister =
        !!data.data?.settings?.seats && !data.data?.settings?.closed;
      dispatch(setAdmin(data));
      dispatch(setCanRegister(canRegister));
    }
  }, [data, isError, isLoading, dispatch]);

  // watches application status update
  useEffect(() => {
    const applicationStatusLoading = closeIsLoading || openIsLoading;
    const applicationStatusError = closeError || openError;
    if (applicationStatusError)
      dispatch(
        SetToast(
          handleErrResponse(applicationStatusError as FetchBaseQueryError)
        )
      );
    if (!applicationStatusLoading && !applicationStatusError) {
      if (!admin.closed && closeData) {
        dispatch(
          SetToast({
            status: "success",
            title: "Application Status",
            message: "Application closed successfully",
          })
        );
        dispatch(setToggleRegistration(closeData));
      } else if (admin.closed && openData) {
        dispatch(
          SetToast({
            status: "success",
            title: "Application Status",
            message: "Application opened successfully",
          })
        );
        dispatch(setToggleRegistration(openData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    closeIsLoading,
    openIsLoading,
    closeError,
    openError,
    closeData,
    openData,
    dispatch,
  ]);

  useEffect(() => {
    setShowAvailModal(false);
  }, [admin.availableSlot]);

  return (
    <S.AdminBackground isMobile={isMobile}>
      <S.AdminContent>
        {isLoading && <Spinner page color="#d9131e" />}
        {!isLoading && (
          <Fragment>
            <S.AdminHeader>
              <S.HeaderTitle>Admin Panel</S.HeaderTitle>
              <S.BtnGroup>
                <S.SendCodeBtn onClick={(): void => setShowCodeModal(true)}>
                  Send QRCode
                </S.SendCodeBtn>
                <S.StatusBtn onClick={handleApplicationStatus}>
                  {!closeIsLoading && !openIsLoading && (
                    <Fragment>
                      {admin.closed ? "Open Application" : "Close Application"}
                    </Fragment>
                  )}
                  {(closeIsLoading || openIsLoading) && (
                    <Spinner color="#d9131e" />
                  )}
                </S.StatusBtn>
              </S.BtnGroup>
              <S.DownloadBtn onClick={downloadGuestFile}>
                Download List
              </S.DownloadBtn>
            </S.AdminHeader>
            <S.CardWrapper>
              <S.Card>
                <S.CardTitle>Total Registered Participants</S.CardTitle>
                <S.CardValue>{admin.totalRegistered}</S.CardValue>
              </S.Card>
              <S.Card>
                <S.CardTitle>Total Scanned QR Code</S.CardTitle>
                <S.CardValue>{admin.totalScanned}</S.CardValue>
              </S.Card>
              <S.Card
                onMouseEnter={() => setIsNewAvail(true)}
                onMouseLeave={() => setIsNewAvail(false)}
              >
                <S.CardTitle>Available Slot</S.CardTitle>
                <S.CardValue>{admin.availableSlot}</S.CardValue>
                {isNewAvail && admin.closed && (
                  <S.ShowNewAvailable
                    onClick={(): void => setShowAvailModal(true)}
                  >
                    Click to Set Availability Count
                  </S.ShowNewAvailable>
                )}
                {isNewAvail && !admin.closed && (
                  <S.ShowNewAvailable $noClick>
                    Close Application before Proceeding
                  </S.ShowNewAvailable>
                )}
              </S.Card>
            </S.CardWrapper>
            <S.LogoutWrapper>
              <Button handleClick={handleLogout}>Logout</Button>
            </S.LogoutWrapper>
          </Fragment>
        )}
      </S.AdminContent>
      {showAvailModal && (
        <UpdateAvailability
          close={closeAvailHandler}
          title="Change Available Slot"
        />
      )}
      {showCodeModal && (
        <ResendQRCode close={closeCodeHandler} title="Re-Send QR Code" />
      )}
    </S.AdminBackground>
  );
};

export default AdminComponent;
