import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FC, useState, useEffect } from "react";
import { SetToast, setSlots, useAppDispatch, useSlotAdjustmentMutation } from "../../redux";
import { InputField, ModalComponent, Button } from "../../common";
import { UnknownType, handleErrResponse } from "../../utils";
import * as S from "./admin.styled";

interface IUpdateAvailability {
  close: () => void;
  title: string;
}

const UpdateAvailability: FC<IUpdateAvailability> = ({ close, title }) => {
  const dispatch = useAppDispatch();
  const [slotAdjustment, { data, isLoading, error }] = useSlotAdjustmentMutation();
  const [availableSlot, setAvailableSlot] = useState("");
  const [availableError, setAvailableError] = useState<null | string>(null);

  const handleChange = (e: UnknownType): void => {
    setAvailableError(null);
    const { value } = e.target;
    const numberRegex = new RegExp(/[0-9]$/);
    const isNumber = numberRegex.test(value) || value === "";
    if (!isNumber) setAvailableError("Input only digits");
    else setAvailableSlot(value);
  };

  const handleSubmit = (): void => {
    if (+availableSlot < 0) setAvailableError("Available Slot cannot be less than zero");
    else slotAdjustment({ seats: +availableSlot });
  };

  useEffect(() => {
    if (error) dispatch(SetToast(handleErrResponse(error as FetchBaseQueryError)));
    if (data) {
      dispatch(SetToast({ status: "success", title: "Seats Update", message: "Seats successfully updated" }));
      dispatch(setSlots(data.data.seats));
    }
  }, [data, isLoading, error, dispatch]);

  return (
    <ModalComponent title={title} close={close}>
      <S.ModalForm onSubmit={handleSubmit}>
        <InputField type="text" name="availableSlot" value={availableSlot} placeholder="Enter Number" errorMessage={availableError} handleChange={handleChange} />
        <Button type="submit" loading={isLoading} handleClick={handleSubmit}>Submit</Button>
      </S.ModalForm>
    </ModalComponent>
  )
}

export default UpdateAvailability;
