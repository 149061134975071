import { FC, useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { Spinner } from "../../common";
import { setOpenScanner, useAppDispatch, useApproveGuestMutation, useGuestDetailsQuery, SetToast, IGuestDetails } from "../../redux";
import ScanResponse from "./scanResponse";
import { getIsMobile, handleErrResponse } from "../../utils";
import * as S from "./scanner.styled";

export const VerifyQRCodeComponent: FC = () => {
  const { userId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [approveGuest, { data: approveData, isLoading: approveLoading, error: approveError }] = useApproveGuestMutation();
  const { data, isLoading, error } = useGuestDetailsQuery(userId as string);
  
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [openResModal, setOpenResModal] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [admitted, setAdmitted] = useState(0);
  const [fetchedGuest, setFetchedGuest] = useState<null | IGuestDetails>(null);

  const possibleList = useMemo(() => Array.from(Array(data?.data?.qr_admits || 0).keys()), [data?.data?.qr_admits])
  const closeScanResHandler = (): void => setOpenResModal(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleAdmitSelection = (value: number) => setAdmitted(value);
  
  const reScanHandler = () => {
    dispatch(setOpenScanner(true));
    navigate("/scanner");
  };

  const handleConfirmation = (): void => {
    approveGuest({ id: userId as string, admitted });
  };

  useEffect(() => {
    if (error) {
      if (JSON.parse(JSON.stringify(error))?.data?.errors?.message) {
        dispatch(SetToast({
          status: "error",
          title: "QR-Code Scan",
          message: JSON.parse(JSON.stringify(error))?.data?.errors?.message
        }));
      } else dispatch(SetToast(handleErrResponse(error as FetchBaseQueryError)));
      dispatch(setOpenScanner(true));
      navigate("/scanner");
    }
    if (data) {
      setFetchedGuest(data.data);
      dispatch(SetToast({
          status: "success",
          title: "QR-Code Scan",
          message: "Details fetch successfully"
        }));
    }
  }, [data, error, dispatch, navigate]);

  useEffect(() => {
    if (approveError) {
      if (JSON.parse(JSON.stringify(error))?.data?.errors?.message) {
        dispatch(SetToast({
          status: "error",
          title: "Guest Approval",
          message: JSON.parse(JSON.stringify(error))?.data?.errors?.message
        }));
      } else dispatch(SetToast(handleErrResponse(error as FetchBaseQueryError)));
      dispatch(setOpenScanner(true));
      navigate("/scanner");
    }
    if (approveData) {
      setIsApproved(true);
      setOpenResModal(true);
    }
  }, [approveData, approveError, dispatch, error, navigate]);

  return (
    <S.ScannerBackground isMobile={isMobile}>
      {isLoading && <Spinner page />}
      {!isLoading && (
        <S.ScannerContent>
          <S.ScannerHeader>
            <S.HeaderTitle>QR-CODE VERIFICATION</S.HeaderTitle>
          </S.ScannerHeader>
          
          <S.DetailsWrapper>
            <S.DetailSection>
              <S.RowSpan>Full Name:</S.RowSpan>
              <S.RowSpan>{fetchedGuest?.first_name} {fetchedGuest?.last_name}</S.RowSpan>
            </S.DetailSection>
            <S.DetailSection>
              <S.RowSpan>Email:</S.RowSpan>
              <S.RowSpan>{fetchedGuest?.email}</S.RowSpan>
            </S.DetailSection>
            <S.DetailSection>
              <S.RowSpan>Contact Number:</S.RowSpan>
              <S.RowSpan>{fetchedGuest?.phone_number}</S.RowSpan>
            </S.DetailSection>
            <S.DetailSection>
              <S.RowSpan>Date Registered:</S.RowSpan>
              <S.RowSpan>{fetchedGuest?.createdAt}</S.RowSpan>
            </S.DetailSection>
            <S.DetailSection>
              <S.RowSpan>Admittance:</S.RowSpan>
              <S.RowSpan>{fetchedGuest?.qr_admits}</S.RowSpan>
            </S.DetailSection>
          </S.DetailsWrapper>

          {fetchedGuest?.qr_admits === 0 && (<S.QuotaPara>You have exceeded the expected quota</S.QuotaPara>)}
          {!!fetchedGuest?.qr_admits && fetchedGuest?.qr_admits > 0 && (
            <S.Admittance>
              <S.AdmitHeader>Select Number(s) to Admit</S.AdmitHeader>
              <S.ListWrapper>
                {possibleList?.map((item) => (
                  <S.PossibleBtn
                    key={item}
                    className={item + 1 === admitted ? "active" : ""}
                    onClick={(): void => handleAdmitSelection(item + 1)}
                  >
                    {item + 1}
                  </S.PossibleBtn>
                ))}
              </S.ListWrapper>
            </S.Admittance>
          )}
          <S.MainBtnContainer>
            <S.BtnGroup>
              <S.ContinueBtn onClick={reScanHandler}>Re-Scan</S.ContinueBtn>
              {!!fetchedGuest?.qr_admits && fetchedGuest?.qr_admits > 0 && (
                <S.ExitBtn onClick={handleConfirmation} disabled={admitted === 0}>Confirm {approveLoading && <Spinner />}</S.ExitBtn>
              )}
            </S.BtnGroup>
          </S.MainBtnContainer>
        </S.ScannerContent>
      )}

      {openResModal && (
        <ScanResponse
          close={closeScanResHandler}
          reScanHandler={reScanHandler}
          isSuccess={isApproved}
          title="Scan Result"
          scanRes={""}
        />
      )}
    </S.ScannerBackground>
  )
}

export default VerifyQRCodeComponent;
