import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getIsMobile } from '../../utils/misc';
import * as S from "./notFound.styled";

interface INotFound {
  to?: string;
  content?: string;
}

export const NotFoundComponent: FC<INotFound> = ({ to = "/", content = "Go Home" }) => {


  const [isMobile, setIsMobile] = useState(getIsMobile());


  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <S.NotFoundBackground isMobile={isMobile}>
      <S.NotFoundContent isMobile={isMobile}>
        <S.Rectangle>
          <h1>404 - Not Found!</h1>
          <Link to={to}>{content}</Link>
        </S.Rectangle>
      </S.NotFoundContent>
    </S.NotFoundBackground>
  );
}

export default NotFoundComponent;