import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/welcome-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/welcome-bg-mobile.jpg';

export const PrivacyBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const PrivacyContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: #ffffff;
  color: #d9131e;
  padding: 1rem 2rem;
  overflow-y: scroll;
`;

export const MainHeader = styled.h1`
  text-transform: uppercase;
  text-align: center;
  height: fit-content;
`;

export const Paragraph = styled.p`
  height: fit-content;
  text-align: justify;
`;

export const Section = styled.div`
  height: fit-content;
  margin-top: 1rem;
  margin-bottom: 0.5rem; 
`;

export const SubHeader = styled.h3`
  text-transform: uppercase;
  height: fit-content;
  margin-bottom: 0.75rem;
`;

export const Anchor = styled.a`
  height: fit-content;
  color: rgba(217, 19, 30, 0.5);
  text-decoration: none;
`;
