import { FC } from "react";
// import { FaTimes } from "react-icons/fa"
import * as S from "./modal.styled";

interface IModal {
  close?: () => void;
  title: string;
  children: JSX.Element;
}

export const ModalComponent: FC<IModal> = ({ children, close, title }) => {

  return (
    <S.ModalWrapper>
      <S.ModalContent>
        {close && (<S.CloseBtn onClick={close}>X</S.CloseBtn>)}
        <S.ModalTitle>{title}</S.ModalTitle>
        {children}
      </S.ModalContent>
    </S.ModalWrapper>
  )
}

export default ModalComponent;
