import { FC } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import * as S from "./spinner.styled";

interface ISpinner {
  page?: boolean;
  color?: string;
}

export const Spinner: FC<ISpinner> = ({ page = false, color = "#ffffff" }) => {
  return (
    <S.Container $page={page} $color={color}>
      <S.SpinnerWrapper $page={page}><AiOutlineLoading3Quarters /></S.SpinnerWrapper>
    </S.Container>
  )
}

export default Spinner;
