import { FC } from "react";
import { UnknownType } from "../../utils";
import * as S from "./input.styled";

interface IIputField {
  type: "text" | "password" | "number" | "email" | "tel";
  name: string;
  value: string;
  label?: string;
  placeholder?: string;
  errorMessage?: string | null;
  handleChange: (e: UnknownType) => void;
}

export const InputField: FC<IIputField> = ({type, name, value, label, placeholder, errorMessage, handleChange}) => {
  return (
    <S.InputFieldWrapper>
      {label && <S.InputLabel>{label}</S.InputLabel>}
      <S.FieldInput type={type} name={name} placeholder={placeholder} value={value} onChange={handleChange} />
      {errorMessage && <S.FieldError>{errorMessage}</S.FieldError>}
    </S.InputFieldWrapper>
  )
}

export default InputField;