import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/welcome-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/welcome-bg-mobile.jpg';

export const AdminBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
`;

export const AdminContent = styled.div`
  min-width: 275px;
  max-width: 80vw;
  box-shadow: 5px 10px 50px 15px rgba(217, 19, 30, 0.75);
  height: 80vh;
  background-color: #ffffff;
  padding: 2rem;
  color: #d9131e;
  overflow: hidden;
  margin: auto;
`;

export const AdminHeader = styled.div`
  position: relative;
  height: 6rem;
  margin-bottom: 1.5rem;

  @media(min-width: 690px) {
    height: fit-content;
  }

  @media(min-width: 769px) {
    height: 6rem;
  }

  @media(min-width: 950px) {
    height: fit-content;
  }
`;

export const HeaderTitle = styled.h2`
  font-size: 2.75rem;
  text-align: center;
  font-family: "BudweiserSerif Bold";
`;

export const BtnGroup = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 0.5rem;

  @media(min-width: 690px) {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
  }

  @media(min-width: 769px) {
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }

  @media(min-width: 950px) {
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: row;
  }
`;

export const SendCodeBtn = styled.button`
  width: fit-content;
  height: fit-content;
  background-color: #d9131e;
  color: #ffffff;
  border: 1px solid #d9131e;
  padding: 0.25rem 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0.2rem;
`;

export const StatusBtn = styled.button`
  width: fit-content;
  height: fit-content;
  background-color: transparent;
  color: #d9131e;
  border: 1px solid #d9131e;
  padding: 0.25rem 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0.2rem;
`;

export const DownloadBtn = styled.button`
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  color: #d9131e;
  padding: 0.25rem 0.5rem;
  left: 3px;
  top: 0.5rem;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0.2rem;
  box-shadow: -3px 2px 5px 3px rgba(217, 19, 30, 0.75);
  border: 1px solid #d9131e;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 100%));
  justify-content: space-between;
  gap: 2rem;
  height: fit-content;

  @media(min-width: 426px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, calc((100% - 2rem) / 2)));
  }

  @media(min-width: 769px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, calc((100% - 4rem) / 3)));
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: #d9131e;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  height: 200px;

  @media(min-width: 1025px)  {
    height: 250px;
  }
`;

export const CardTitle = styled.p`
  text-align: center;
  max-width: 250px;
  height: fit-content;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 1rem;
`;

export const CardValue = styled.div`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: #ffffff;
  color: #d9131e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 900;
`;

export const ShowNewAvailable = styled.div<{$noClick?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  color: #d9131e;
  cursor: (${(props): StyledProps => props.$noClick ? "normal" : "pointer"});
  font-size: 1.35rem;
  font-weight: 700;
  text-align: center;
`;

export const LogoutWrapper = styled.div`
  height: fit-content;
  width: fit-content;
  margin: 0.5rem 0 0.5rem auto;
`;

// Modal form styles here!!!

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

export const SubmitButton = styled.button`
  background-color: #d9131e;
  color: #ffffff;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    background-color: rgba(217, 19, 30, 0.25);
  }
`;
