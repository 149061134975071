import { FC, useState, useEffect, Fragment } from "react";
import { getIsMobile } from "../../utils";
import ScanQRCode from "./scanQRCode";
import { useAppSelector, useAppDispatch, setOpenScanner, useScannerDashboardQuery, setScanner, initialState, setLogout, SetToast } from "../../redux";
import { Spinner } from "../../common";
import * as S from "./scanner.styled";
import { useNavigate } from "react-router-dom";

export const ScannerComponent: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openScanner, scanner } = useAppSelector(state => state.appstate);
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [scanData, setScanData] = useState<null | string>(null);

  const { data, isError, isLoading } = useScannerDashboardQuery();

  const closeScannerHandler = (): void => {dispatch(setOpenScanner(false))};

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (scanData) {
      if (scanData.includes("localhost")) {
        dispatch(SetToast({ status: "error", title: "Scan Response", message: "Invalid QR Code" }));
        dispatch(setOpenScanner(false));
      } else {
        window.location.replace(scanData);
        dispatch(setOpenScanner(false));
      }
    }
  }, [dispatch, scanData]);

  useEffect(() => {
    if (data && !isError && !isLoading) {
      dispatch(setScanner(data));
    }

  }, [data, isError, isLoading, dispatch]);

  const handleLogout = (): void => {
    dispatch(setLogout(initialState));
    localStorage.removeItem(process.env.REACT_APP_AUTH_TOKEN as string);
    navigate("/login");
  };

  return (
    <S.ScannerBackground isMobile={isMobile}>
      <S.ScannerContent>
        {isLoading && <Spinner page color="#d9131e" />}
        {!isLoading && data && (
          <Fragment>
          <S.ScannerHeader>
            <S.HeaderTitle>Scanner Panel</S.HeaderTitle>
            <S.ScanCodeBtn onClick={(): void => {dispatch(setOpenScanner(true))}}>Scan QRCode</S.ScanCodeBtn>
            <S.DownloadBtn onClick={handleLogout}>Logout</S.DownloadBtn>
          </S.ScannerHeader>

          <S.CardWrapper>
            <S.Card>
              <S.CardTitle>Total Scanned Code</S.CardTitle>
                <S.CardValue>{`${scanner.successfulScanned}`}</S.CardValue>
            </S.Card>
          </S.CardWrapper>  
          </Fragment>
        )}

      </S.ScannerContent>
      {openScanner && (
        <ScanQRCode
          close={closeScannerHandler}
          title="Scan QR Code"
          setScanData={setScanData}
        />
      )}
    </S.ScannerBackground>
  )
}

export default ScannerComponent;
