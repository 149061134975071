import axios from "axios";

export const downloadGuestFile = async () => {
  try {
    const baserURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baserURL}/admin/download/guest`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    handleDownload(response.data, "guest");
  } catch (error) {
    console.log(error, ">>>>>>>>");
  }
};

const handleDownload = (data: string, name: string) => {
  const blob = new Blob([data], {type: "text/csv"});
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${name}.csv`;
  a.click();
  URL.revokeObjectURL(url);
};

export const downloadAttendeesFile = async () => {
  try {
    const baserURL = process.env.REACT_APP_BASE_URL;
    const token = localStorage.getItem("token");
    const response = await axios.get(`${baserURL}/admin/download/guest`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    handleDownload(response.data, "guest");
  } catch (error) {
    console.log(error);
  }
};
