import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/form-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/form-bg-mobile.jpg';

export const WelcomeBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
`;

export const PrivacyBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.4rem 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  cursor: pointer;
  color: #ffffff;
  background-color: transparent;
  height: fit-content;
  width: fit-content;
  border-radius: 0.25rem;
  &:hover {
    opacity: 0.7;
  }
`;

export const WelcomeContent = styled.div<{ isMobile: boolean }>`
  min-width: 275px;
  max-width: 45vw;
  box-shadow: 5px 10px 50px 15px rgba(217, 19, 30, 0.75);
  height: fit-content;
  transform: ${(props): StyledProps => props.isMobile ? "translateY(0%)" : "translateY(20%)"};
  background-color: transparent;
  color: #d9131e;
  overflow: hidden;
  margin: auto;
  position: relative;
`;

export const ClosureWrapper = styled.div`
  background-color: #ffffff;
  padding: 1rem 5rem;
  border: 10px solid #d9131e;

  @media (max-width: 1024px) {
    padding: 1rem 2rem;
  }

  @media (max-width: 768px) {
    padding: 1rem 2rem;
  }
`;

export const Question = styled.p`
  color: #d9131e;
  font-size: 3rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-family: "BudweiserSerif Bold";
  text-align: center;
`;

export const Response = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;

  button {
    text-transform: uppercase;
    color: #ffffff;
    padding-bottom: 0.375rem;
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
    width: 9rem;
    font-family: "BudweiserSerif Bold";
    cursor: pointer;
    &:hover {
      opacity: 0.85;
    }
  }
`;

export const Positive = styled.button`
  background-color: #000000;
`;

export const Negative = styled.button`
  background-color: #d9131e;
`;

export const ClosureHeader = styled.p`
  color: #ffffff;
  font-size: 5rem;
  text-align: center;
  font-style: italic;
  font-weight: 700;
  transform: translateY(15px);
`;

export const Closure = styled.p`
  font-family: "BudweiserSerif Bold";
  font-size: 3rem;
  line-height: 1.25;
  text-align: center;
`;
