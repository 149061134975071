import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/welcome-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/welcome-bg-mobile.jpg';

export const ScannerBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
`;

export const ScannerContent = styled.div`
  min-width: 275px;
  max-width: 80vw;
  box-shadow: 5px 10px 50px 15px rgba(217, 19, 30, 0.75);
  height: 80vh;
  background-color: #ffffff;
  padding: 2rem;
  color: #d9131e;
  overflow-y: scroll;
  scrollbar-color: #ff0;
  margin: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #d9131e; 
  }
  &::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    border-radius: 20px;
    border: 2px solid #d9131e;
  }
`;

export const ScannerHeader = styled.div`
  position: relative;
  height: fit-content;
  margin-bottom: 1.5rem;
`;

export const HeaderTitle = styled.h2`
  font-size: 2.75rem;
  text-align: center;
  font-family: "BudweiserSerif Bold";
`;

export const DownloadBtn = styled.button`
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: #d9131e;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  right: 0;
  top: 1rem;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0.2rem;
`;
export const ScanCodeBtn = styled.button`
  position: absolute;
  width: fit-content;
  height: fit-content;
  background-color: #ffffff;
  color: #d9131e;
  padding: 0.25rem 0.5rem;
  left: 3px;
  top: 1rem;
  right: 0;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  border-radius: 0.2rem;
  box-shadow: -3px 2px 5px 3px rgba(217, 19, 30, 0.75);
  border: 1px solid #d9131e;
`;

export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 100%));
  justify-content: space-between;
  gap: 2rem;
  height: fit-content;

  @media(min-width: 426px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, calc((100% - 2rem) / 2)));
  }

  @media(min-width: 769px) {
    grid-template-columns: repeat(auto-fill, minmax(100px, calc((100% - 4rem) / 3)));
  }
`;

export const Card = styled.div`
  position: relative;
  background-color: #d9131e;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  height: 200px;

  @media(min-width: 1025px)  {
    height: 250px;
  }
`;

export const CardTitle = styled.p`
  text-align: center;
  max-width: 250px;
  height: fit-content;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 0 1rem;
`;

export const CardValue = styled.div`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  background-color: #ffffff;
  color: #d9131e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: 900;
`;

// Modal form styles here!!!

export const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 500px;
`;

export const SubmitButton = styled.button`
  background-color: #d9131e;
  color: #ffffff;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 0.2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.85;
  }

  &:disabled {
    background-color: rgba(217, 19, 30, 0.25);
  }
`;

export const IconWrapper = styled.div`
  width: 8rem;
  margin: 0 auto;
`;

export const ResponseText = styled.p`
  text-align: center;
  font-size: 1.75rem;
  color: #d9131e;
  font-family: "BudweiserSerif Regular";
  font-weight: 600;
`;

export const BtnGroup = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;

  button {
    color: #ffffff;
    padding: 0.175rem 1rem 0.375rem 1rem;
    width: fit-content;
    font-size: 1.1rem;
    font-weight: 900;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    &:hover {
      opacity: 0.85;
    }
  }
`;

export const ContinueBtn = styled.button`
  background-color: #000000;
`;

export const ExitBtn = styled.button`
  background-color: #d9131e;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  &:disabled {
    opacity: 0.5;
  }
`;

export const SingleResponse = styled.div`
  margin-bottom: 1rem;
`;

export const DetailsWrapper = styled.div`
  height: fit-content;
  border: 1px solid #d9131e;
  border-radius: 0.25rem;
`;

export const DetailSection = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 2rem;
  height: fit-content;
  padding: 0.5rem 2rem;

  &:nth-child(odd) {
    background-color: rgba(217, 19, 31, 0.2);
  }
`;

export const RowSpan = styled.span`
  height: fit-content;

  &:first-child {
    width: 11rem;
  }

  &:nth-child(2) {
    font-weight: 700;
  }
`;

export const MainBtnContainer = styled.div`
  max-height: 2.5rem;
  margin-top: 1rem;
`;

export const QuotaPara = styled.p`
  height: fit-content;
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 700;
  font-size: 1.2rem;
`;

export const Admittance = styled.div`
  height: fit-content;
  margin-top: 1rem;
`;

export const AdmitHeader = styled.p`
  font-size: 1.05rem;
  font-weight: 600;
`;

export const ListWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  margin: 1rem auto;
`;

export const PossibleBtn = styled.button`
  height: fit-content;
  width: 2.5rem;
  padding: 0.25rem 0;
  cursor: pointer;
  background-color: rgba(217, 19, 31, 1);
  font-weight: 700;
  font-size: 1.25rem;
  color: #ffffff;
  border-radius: 0.15rem;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
`;
