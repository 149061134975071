import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import jwt from "jsonwebtoken"
import { apiErrorType } from "./constant";
import { GenericFormType, GenericErrorType, ErrResponseType } from "./typings";

export const getIsMobile = () => {
  if (typeof window === "undefined") {
    return true;
  }
  const width = window.innerWidth;
  const height = window.innerHeight;
  return width < height;
};

export const validateName = (name: string): string | null => {
  if (name.length === 0) return "Field cannot be empty";
  if (name.length < 2) return "Invalid character length";
  return null;
};

export const validatePhone = (phone: string): string | null => {
  if (phone.length === 0) return "Field cannot be empty";
  const phoneRegex = new RegExp(/^[+]?[0-9]{11,13}$/);
  const isPhone = phoneRegex.test(phone);
  if (!isPhone) return "Invalid phone number format";
  return null;
};

export const validatePin = (pin: string): string | null => {
  if (pin.length === 0) return "Field cannot be empty";
  const pinRegex = new RegExp(/^[0-9]{5,15}$/);
  const isPin = pinRegex.test(pin);
  if (!isPin) return "Invalid pin format";
  return null;
}

export const validateEmail = (email: string): string | null => {
  if (email.length === 0) return "Field cannot be empty";
  const emailRegEx = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);
  const isEmail = emailRegEx.test(email);
  if (!isEmail) return "Invalid email format";
  return null;
};

export const handleApplicationValidation = (formData: GenericFormType): GenericErrorType => {
  const first_name = validateName(formData.first_name);
  const last_name = validateName(formData.last_name);
  const email = validateEmail(formData.email);
  const phone_number = validatePhone(formData.phone_number);
  return { first_name, last_name, email, phone_number };
};

export const handleErrResponse = (error: FetchBaseQueryError | undefined): ErrResponseType => {
  if (!error?.status) return { title: "Oops", message: "Something went wrong", status: "error" };
  if (!Number.isInteger(+error?.status)) return { title: String(error?.status).split("_").join(" "), message: "Unable to process your request", status: "error" };
  const dataError = error?.data as any;
  const type: string = dataError?.errors?.type;
  return { title: type?.split("_").join(" ") || "Oops", message: apiErrorType[type] || "Unable to process your request", status: "error" };
};

export const handleLoginValidation = (formData: GenericFormType): GenericErrorType => {
  const email = validateEmail(formData.email);
  const pin = validatePin(formData.pin);
  return { email, pin };
};

export const extractTokenData = () => {
  const tokenKey = process.env.REACT_APP_AUTH_TOKEN as string;
  const secret = process.env.REACT_APP_SECRET as string;
  const token = localStorage.getItem(tokenKey);
  if (!token) return null;
  try {
    const decoded = jwt.verify(token, secret);
    if (typeof decoded === "string") return null;
    const { id, email, role } = decoded;
    return { id, email, role };
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const extractUrls = () => {
  const pathName = window.location.pathname.split("/");
  const isAdminUrl = pathName[1] === "admin";
  const isScannerUrl = pathName[1] === "scanner" || pathName[1] === "guest";
  return { isAdminUrl, isScannerUrl };
};
