import styled from "styled-components";
import { StyledProps } from "../../utils";

const desktop = process.env.PUBLIC_URL + '/assets/images/form-bg.jpg';
const mobile = process.env.PUBLIC_URL + '/assets/images/form-bg-mobile.jpg';

export const AuthBackground = styled.div<{ isMobile: boolean }>`
  background: url(${(props): StyledProps => props.isMobile ? mobile : desktop});
  background-size: 100%;
  width: 100%;
  height: 100vh;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
`;

export const AuthContent = styled.div<{ isMobile: boolean }>`
  min-width: 275px;
  max-width: 40rem;
  box-shadow: 5px 10px 50px 15px rgba(217, 19, 30, 0.75);
  height: fit-content;
  transform: ${(props): StyledProps => props.isMobile ? "translateY(30%)" : "translateY(10%)"};
  background-color: #ffffff;
  padding-top: 1rem;
  color: #d9131e;
  overflow: hidden;
  margin: auto;
`;

export const AuthHeader = styled.h3`
  text-align: center;
  font-family: "BudweiserSerif Regular";
  font-size: 3rem;
  margin-bottom: 1rem;
  height: fit-content;
`;

export const AuthForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem 3rem;
  background-color: #eeeeee;
  height: fit-content;
`;

export const LoginWrapper = styled.div`
  min-width: 275px;
  max-width: 40rem;
`;