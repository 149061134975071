import {FC, useEffect, Fragment} from "react";
import {Route, Routes} from "react-router-dom";
import {useAppDispatch, setUser, useAppSelector} from "./redux";
import {extractTokenData, extractUrls} from "./utils";
import {
  WelcomeComponent,
  LoginComponent,
  PrivacyComponent,
  AdminComponent,
  ApplicationComponent,
  NoAccessComponent,
  ScannerComponent,
  VerifyQRCodeComponent,
  NotFoundComponent,
} from "./components";

export const ErrorHandleRoutes = () => {
  const {isAdminUrl, isScannerUrl} = extractUrls();
  const {isLoggedIn} = useAppSelector((state) => state.appstate.user);

  return (
    <Routes>
      <Route path="no-access" element={<NoAccessComponent />} />
      {!isLoggedIn && (isAdminUrl || isScannerUrl) && (
        <Route
          path="*"
          element={<NoAccessComponent to="/login" content="Back to Login" />}
        />
      )}
      {!isLoggedIn && !isAdminUrl && !isScannerUrl && (
        <Route path="*" element={<NotFoundComponent />} />
      )}
    </Routes>
  );
};

export const UnAuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomeComponent />} />
      <Route path="login" element={<LoginComponent />} />
      <Route path="privacy" element={<PrivacyComponent />} />
      <Route path="application" element={<ApplicationComponent />} />
    </Routes>
  );
};

export const AuthenticatedRoutes = () => {
  const {isAdminUrl, isScannerUrl} = extractUrls();
  const {role} = useAppSelector((state) => state.appstate.user);
  const isAdmin = isAdminUrl && role.toUpperCase() === "ADMIN";
  const isScanner = isScannerUrl && role.toUpperCase() === "SCANNER";
  let urlTo = "login";
  if (isAdmin) urlTo = "/admin";
  if (isScanner) urlTo = "/scanner";

  return (
    <Routes>
      {isAdmin && <Route path="admin" element={<AdminComponent />} />}
      {isScanner && <Route path="scanner" element={<ScannerComponent />} />}
      {isScanner && (
        <Route path="guest/:userId" element={<VerifyQRCodeComponent />} />
      )}
      <Route
        path="*"
        element={<NotFoundComponent to={urlTo} content="Back to Dashboard" />}
      />
    </Routes>
  );
};

export const Router: FC = () => {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector((state) => state.appstate);

  useEffect(() => {
    const tokenizedData = extractTokenData();
    if (tokenizedData) {
      const {id, role, email} = tokenizedData;
      dispatch(setUser({id, role, email, isLoggedIn: true}));
    }
  }, [dispatch]);

  return (
    <Fragment>
      <UnAuthenticatedRoutes />
      {user.isLoggedIn && <AuthenticatedRoutes />}
      <ErrorHandleRoutes />
    </Fragment>
  );
};

export default Router;
