import styled from "styled-components";

export const Wrapper = styled.div`
  height: fit-content;
`;

export const ToastWrapper = styled.div`
  display: block;
`;

export const ToastTitle = styled.h2`
  font-size: 1rem;
  font-family: "DM Serif Display";
  text-transform: capitalize;
`;

export const ToastMessage = styled.h2`
  font-size: 0.8rem;
`;