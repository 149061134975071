import { Toast } from './common';
import Router from './Router';

function App() {

  return (
    <div className="App">
      <Toast />
      <Router />
    </div>
  );
}

export default App;
