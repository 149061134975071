import styled from "styled-components";

export const InputFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  background-color: transparent;
`;

export const InputLabel = styled.label`
  font-size: 1rem;
`;

export const FieldInput = styled.input`
  font-size: 1.25rem;
  color: #d9131e;
  border: 1px solid #d9131e;
  border-radius: 0.2rem;
  padding: 0.75rem 1rem;
  background-color: transparent;

  &::placeholder {
    font-size: 85%;
  }
`;

export const FieldError = styled.p`
  font-size: 0.75rem;
`;
