import React from "react";
import * as S from "./application.styled";

interface ITermsCondition {
  close: () => void;
}

export const TermsCondition: React.FC<ITermsCondition> = ({ close }) => {
  return (
    <S.TermWrapper>
      <S.TermsHeader>Ticketing Terms and Conditions</S.TermsHeader>

      <S.Paragraph>Please read this alongside our main Terms of Use</S.Paragraph>
      <S.Paragraph>
        Our website is owned and operated by International Breweries Plc (“IB Plc” or we or “organizer”). These terms and conditions apply to tickets for performances and events and references in these terms to 'performances' will also include events.
      </S.Paragraph>

      <S.Section>
        <S.SubHeader>Introduction</S.SubHeader>
        <S.Paragraph>
          By holding and/or being in possession of a Ticket or Tickets, the holder of the Ticket (“Ticket holder”, “you”, “your”) agrees to and is bound by these terms and conditions (“Ticketing Terms”).
        </S.Paragraph>
        <S.Paragraph>
          The provisions of these Ticketing Terms are for the benefit of the Ticket holder and/or IB Plc and may be accepted by the Venue at any time. The ticket is available to persons who are 18 years or above 18 years and not available to persons below the age of 18.
        </S.Paragraph>
      </S.Section>

      <S.Section>
        <S.SubHeader>The ticketing terms</S.SubHeader>
        <S.ListWrapper>
          <S.ListItem>
            Each Ticket gotten through the Digital Forms on IB Plc Platforms that IB Plc will share is intended to give you a revocable license (or right) to enter and attendance of the particular Event only to which the Ticket pertains. Each Ticket holder has a right to a seat or standing place in relation to the stage, of a value corresponding to that stated on the Ticket holder’s Ticket. IB Plc and the Venue reserve the right to provide alternative seats or standing places to those specified on the Ticket.
          </S.ListItem>
          <S.ListItem>
            A Ticket does not afford the Ticket holder the right to transport to the Venue or parking, merchandise, or any food or beverages at the Venue unless otherwise stated. Other independent service providers might render services relating to transport, parking, merchandise, food, or beverages at the Venue, and in such circumstances, the terms and conditions of such service providers will apply between it and persons who make use of such products or services.
          </S.ListItem>
          <S.ListItem>
            You must produce a valid Ticket to gain entry to the Venue and you must produce that Ticket for inspection upon request from an Event official at any time. Failure to produce such a Ticket when requested may result in you being denied access to the Venue or in you being removed from the Venue.
          </S.ListItem>
          <S.ListItem>
            Tickets are not transferable and cannot be resold or offered for resale (including online auction sites) or used for commercial purposes including advertising, competitions, contests, sweepstakes, promotions, or used, sold, or given away in conjunction with other products or services (such as part of a travel or hospitality package) without the express written permission of IB Plc in advance. If this condition is breached the Ticket may be cancelled without compensation and the bearer of the Ticket may be refused entry or removed from the Venue.
          </S.ListItem>
          <S.ListItem>
            Any Tickets bought from unauthorized agents or third parties are not valid and admission to an Event on the strength of such Tickets will not be permitted. All Tickets remain the property of IB Plc at all times and constitute a personal revocable license from IB Plc to the Ticket holder which may be withdrawn by IB Plc and admission refused at any.
          </S.ListItem>
          <S.ListItem>
            We reserve the right to restrict Tickets to a maximum number per person, and/or per household/organization and to cancel any Tickets in excess of this number.
          </S.ListItem>
          <S.ListItem>
            It is your responsibility to (i) ensure that your Tickets are collected by or delivered to you before the date of the Event; (ii) keep your Tickets safe prior to the Event; and (iii) check your Tickets and to ascertain the date and the time of the Event and if applicable, any rescheduled Event. Event dates and times are subject to change. IB Plc is not responsible or liable should you fail to obtain your Tickets timeously or for any Tickets that are lost, stolen, or damaged.
          </S.ListItem>
          <S.ListItem>
            Your possession of Tickets is subject to our right to cancel or reschedule an Event at any time, or to alter or vary the program of an Event, due to circumstances beyond our reasonable control. Where we alter or vary the programme of an Event, we shall not be obligated to pay you for any Ticket or exchange your Tickets. Please note that if an opening act or guest artist is changed or cancelled, the event is not considered cancelled. We shall have no liability to you in circumstances where an Event is cancelled due to circumstances beyond our reasonable control.
          </S.ListItem>
          <S.ListItem>
            If an Event is rescheduled or cancelled, we will use our reasonable endeavours to notify you as soon as reasonably possible thereof (including notifying you of the details of any rescheduled Event where the event was rescheduled) by publishing the details of the rescheduled or cancelled Event, as the case may be, by e-mail and/or short message service (“SMS”) notification to the contact details which you provide at the time of the obtaining of Tickets. It is therefore important that you provide us (whichever is applicable) with your correct contact details at the time of winning the ticket of Tickets.
          </S.ListItem>
          <S.ListItem>
            The following restrictions will apply to your attendance at an Event and your breach of any of these may lead to you being ejected from the Venue:
            <ol>
              <S.ListItem>
                Appropriate standards of behaviour are expected of all persons attending an Event and persons deemed to be behaving in a manner that is dangerous or unacceptable in the opinion of Event organizers or its agents or contractors, will be refused admission and/or removed from the Venue with no refund on the Ticket. Activities such as ‘stage diving’, ‘moshing’, ‘climbing’ and ‘crowd surfing’ are strictly prohibited for your safety.
              </S.ListItem>
              <S.ListItem>
                To facilitate the security, safety, and comfort of all patrons, we reserve the right in our reasonable discretion to conduct security searches of persons, clothing, bags, and all other items on entry and exit of a Venue, to confiscate items which may cause danger or disruption to the Event or other patrons or that are in breach of the Ticketing Terms and/or to refuse admission.
              </S.ListItem>
              <S.ListItem>
                Ticket holders may be ejected from the Venue if, in the reasonable opinion of Event organizers or its agents or any contractors appointed by it, or the Venue, the Ticket holder is a risk to the safety of any patron and/or may affect the enjoyment of other patrons and/or the running of the Event or may cause damage, nuisance or injury. Examples include being (or appearing to be) drunk, incapable, intoxicated, or under the influence of illegal drugs, under-age (where relevant), abusive, threatening, behaving antisocially, carrying offensive weapons or illegal substances, declining to be searched or in breach of any of these Ticketing Terms or the terms and conditions of the Venue.
              </S.ListItem>
              <S.ListItem>
                There may be instances where there are to be no pass-outs or readme
              </S.ListItem>
              <S.ListItem>
                The following items are not permitted to be brought into a Venue:
                <ol>
                  <S.ListItem>
                    Glass bottles or containers or other heavy and/or sharp objects, which could potentially be used as projectiles (excluding sunglasses, binoculars, and prescription or reading glasses)
                  </S.ListItem>
                  <S.ListItem>
                    Any illegal drugs
                  </S.ListItem>
                  <S.ListItem>
                    Bicycles, scooters, or vehicles of such nature
                  </S.ListItem>
                  <S.ListItem>
                    Knives, weapons of any nature, or fireworks
                  </S.ListItem>
                  <S.ListItem>
                    Any object that could be used to distract, hinder or interfere with any performer, including laser pointers and flashlights
                  </S.ListItem>
                  <S.ListItem>
                    Flags with poles, large flags, or banners that may obstruct the view of other patrons
                  </S.ListItem>
                  <S.ListItem>
                    Any whistle, horn, musical instrument, loud hailer, public address system, or vuvuzela
                  </S.ListItem>
                  <S.ListItem>
                    Digital, electronic, or other recording or broadcast device
                  </S.ListItem>
                  <S.ListItem>
                    Any item that you intend to distribute, hawk, sell, offer, expose for sale or display for marketing or promotional purposes
                  </S.ListItem>
                  <S.ListItem>
                    No animals apart from authorised guide/assistance dogs
                  </S.ListItem>
                  <S.ListItem>
                    Any dangerous goods and any other items by management to be dangerous or capable of causing a public nuisance
                  </S.ListItem>
                  <S.ListItem>
                    Professional cameras or video recorders
                  </S.ListItem>
                  <S.ListItem>
                    Umbrellas (unless there is an obvious threat of rain), in which case small umbrellas will be permitted but no golf or beach umbrellas
                  </S.ListItem>
                  <S.ListItem>
                    Braais or barbeques
                  </S.ListItem>
                  <S.ListItem>
                    Camping Chairs
                  </S.ListItem>
                  <S.ListItem>
                    Cooler Boxes
                  </S.ListItem>
                  <S.ListItem>
                    Food and refreshments (including soft drinks and alcohol).
                  </S.ListItem>
                </ol>
              </S.ListItem>
              <S.ListItem>
                Please note that in seated areas, other members of the audience may stand up during the Event.
              </S.ListItem>
              <S.ListItem>
                Smoking is not permitted in the Venue.
              </S.ListItem>
              <S.ListItem>
                The unauthorised use of photographic, video, and/or sound recording equipment is prohibited.
              </S.ListItem>
              <S.ListItem>
                Efforts will be made to admit latecomers at an appropriate break in the performance of the Event. Entry will not be permitted if it will disrupt the performance.
              </S.ListItem>
            </ol>
          </S.ListItem>
          <S.ListItem>
            If you are a disabled person, you are requested to inform the Event organizer, its agents, and contractors will use their reasonable endeavours to accommodate and assist you at the Event, subject to their capacity to do so, and the confines and layout of the Venue facilities.
          </S.ListItem>
          <S.ListItem>
            The Event is age-restricted to persons of 18 years and above and it is the responsibility of the Ticket holder to check age restrictions to an Event before purchasing or competing for a Ticket.
          </S.ListItem>
          <S.ListItem>
            Please note that CCTV and film cameras may be present at the Venue, and by holding this ticket you grant permission to the organizers of the event and their agents to take photographs and/or video recordings of you during the event. You also grant permission to use any such photographs and/or recordings for promotional and marketing purposes, including but not limited to, on websites, social media, advertising, and other promotional materials.
          </S.ListItem>
          <S.ListItem>
            You hereby release the organizers of the event and their agents from any and all claims, demands, or causes of action that you may have now or in the future arising out of or in connection with the use of these photographs and/or recordings.
          </S.ListItem>
          <S.ListItem>
            By clicking the "I agree" button or using your ticket for entry to the event, you acknowledge that you have read and understood this release, and that you agree to be bound by its terms.
          </S.ListItem>
          <S.ListItem>
            You voluntarily assume all risks and dangers incidental to an Event, whether occurring before, during, or after the Event, and you waive any claims for loss, damage, personal injury, or death against IB Plc, the management of IB Plc, any of its agents, the performing artist(s), participants, and all of their respective parents, affiliated entities, agents, officers, directors, owners, and employees on behalf of yourself.
          </S.ListItem>
          <S.ListItem>
            IB Plc will not accept responsibility for damage to or loss of any personal property at an Event.
          </S.ListItem>
          <S.ListItem>
            You bear the risk of inclement weather.
          </S.ListItem>
          <S.ListItem>
            To the fullest extent possible in law, we do not accept liability for and you will have no claim in respect of:
            <ol>
              <S.ListItem>
                any errors relating to the location of your seat or standing place relative to the stage as indicated on the seating plan prepared for the Venue; or
              </S.ListItem>
              <S.ListItem>
                the quality of the view from your seat or standing place; or
              </S.ListItem>
              <S.ListItem>
                the lack of or inadequacy of parking at the Venue; or
              </S.ListItem>
              <S.ListItem>
                any problems relating to the adequacy of traffic control to and from the Venue; or
              </S.ListItem>
              <S.ListItem>
                the lack of or inadequacy of food and beverages at the Venue; or
              </S.ListItem>
              <S.ListItem>
                the quality of sound and lighting at the Event; or
              </S.ListItem>
              <S.ListItem>
                the quality of any services provided by independent contractors at the Venue.
              </S.ListItem>
            </ol>
          </S.ListItem>
          <S.ListItem>
            You agree and acknowledge that neither IB Plc nor any of its agents have guaranteed or made any warranties or representations to you about the accuracy of the location of your seat or standing place relative to the stage as indicated on the seating plan prepared for the Venue, the availability of parking at the Venue and/or the adequacy of traffic control to or from the Venue.
          </S.ListItem>
          <S.ListItem>
            If any provision of these Ticketing Terms is or becomes illegal, invalid, or unenforceable in any jurisdiction that shall not affect the legality, validity, or enforceability in that jurisdiction of any other provision of these Ticketing Terms; or the legality, validity or enforceability in any other jurisdiction of that or any other provision of these Ticketing Terms.
          </S.ListItem>
          <S.ListItem>
            No indulgence which IB Plc or any of its agents and/or contractors (the “Grantor”) may grant to you and/or the Ticket holder (the “Grantee”) shall constitute a waiver of any of the rights of the Grantor, who shall not thereby be precluded from exercising any rights against the Grantee which might have arisen in the past or which might arise in the future.
          </S.ListItem>
          <S.ListItem>
            These Ticketing Terms and Conditions Agreement cancel and supersede all prior negotiations and previous agreements, understandings, and arrangements, whether written or oral, between you and IB Plc or any of its agents and/or contractors.
          </S.ListItem>
          <S.ListItem>
            These Ticketing Terms constitute the whole agreement between you and/or the Ticket holder and IB Plc in respect of the subject matter hereof and no addition to or variation or novation of this agreement shall be of any force or effect unless reduced to writing and signed by the above parties or their duly authorized representatives.
          </S.ListItem>
          <S.ListItem>
            These Ticketing Terms shall be governed by Nigerian Law and be subject to the exclusive jurisdiction of the Nigerian courts.
          </S.ListItem>
        </S.ListWrapper>
      </S.Section>
      <S.CloseBtn onClick={close}>Close</S.CloseBtn>
    </S.TermWrapper>
  )
}

export default TermsCondition;