import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "../../common";
import { FaCheckCircle } from "react-icons/fa";
import { MdError } from "react-icons/md";
import * as S from "./scanner.styled";

interface IScanResponse {
  title: string;
  scanRes: string | null;
  isSuccess: boolean;
  close: () => void;
  reScanHandler: () => void;
}

const ScanResponse: FC<IScanResponse> = ({ scanRes, title, close, reScanHandler, isSuccess }) => {
  const navigate = useNavigate();

  const closeHandler = (): void => {
    navigate("/scanner");
    close();
  }

  return (
    <ModalComponent title={title} close={close}>
      <S.ModalForm>
        <p>{scanRes}</p>
        {isSuccess && (
          <S.SingleResponse>
            <S.IconWrapper>
              <FaCheckCircle color="#d9131e" />
            </S.IconWrapper>
            <S.ResponseText>Access Granted</S.ResponseText>
          </S.SingleResponse>
        )}
        {!isSuccess && (
          <S.SingleResponse>
            <S.IconWrapper>
              <MdError color="#d9131e" />
            </S.IconWrapper>
            <S.ResponseText>Access Denied</S.ResponseText>
          </S.SingleResponse>
        )}

        <S.BtnGroup>
          <S.ContinueBtn onClick={reScanHandler}>Scan New</S.ContinueBtn>
          <S.ExitBtn onClick={closeHandler}>Close</S.ExitBtn>
        </S.BtnGroup>
      </S.ModalForm>

    </ModalComponent>
  )
}

export default ScanResponse;