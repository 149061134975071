import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector, useFetchAvailabilityQuery } from "../../redux";
import { getIsMobile } from "../../utils/misc";
import * as S from "./welcome.styled";

export const WelcomeComponent: FC = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useFetchAvailabilityQuery();
  const aa = useAppSelector(state => state.appstate);

  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handlePrivacyClick = (): void => navigate("/privacy");
  console.log(data, isLoading, isError, aa);
  return (
    <S.WelcomeBackground isMobile={isMobile}>
      <S.PrivacyBtn onClick={handlePrivacyClick}>Privacy Policy</S.PrivacyBtn>
      <S.WelcomeContent isMobile={isMobile}>
        <S.ClosureHeader>HEY BUD.</S.ClosureHeader>
        <S.ClosureWrapper>
          <S.Closure>Our limited seats at BudFest have been filled. <br /> Thank you for your interest and keep an eye out for our next event.</S.Closure>
        </S.ClosureWrapper>
      </S.WelcomeContent>
    </S.WelcomeBackground>
  )
}

export default WelcomeComponent;
