import styled, { keyframes } from "styled-components";
import { StyledProps } from "../../utils";

export const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.div<{ $page: boolean;  $color: string}>`
  width: ${(props): StyledProps => props.$page ? "100%" : "fit-content"};
  color: ${(props): StyledProps => props.$color };
  
  & svg {
    transition: opacity 200ms;
    animation: ${rotate} 1s linear;
    animation-iteration-count: infinite;
  }
`;

export const SpinnerWrapper = styled.div<{$page: boolean}>`
  width: ${(props): StyledProps => props.$page ? "5rem" : "1rem"};
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.2rem;
`;