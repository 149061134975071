import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { FC, useState, useEffect } from "react";
import { Button, InputField } from "../../common"
import { SetToast, useAppDispatch, useRegisterGuestMutation } from "../../redux";
import {
  defaultFormData,
  defaultFormError,
  getIsMobile,
  handleApplicationValidation,
  handleErrResponse,
  UnknownType,
} from "../../utils";
import TermsCondition from "./TermsCondition";
import SuccessApplication from "./SuccessApplication";
import * as S from "./application.styled";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

export const ApplicationComponent: FC = () => {
  const [registerGuest, { data, isLoading, error }] = useRegisterGuestMutation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(getIsMobile());
  const [formData, setFormData] = useState(defaultFormData);
  const [formError, setFormError] = useState(defaultFormError);
  const [termsOpen, setTermsOpen] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);
  const [termsError, setTermsError] = useState<null | string>(null);
  const [successModal, setSuccessModal] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => navigate("/"), [navigate]);

  const updateState = (key: string, value: string): void => {
    if (termsError) setTermsError(null);
    if (formError[key]) setFormError({ ...formError, [key]: null });
    setFormData({ ...formData, [key]: value });
  }

  const handleChange = (e: UnknownType): void => {
    e.preventDefault();
    const { name, value } = e.target;
    updateState(name, value);
  }

  const handleTermsClick = (): void => setTermsOpen(true);
  const handleCheckBox = (): void => {
    if (termsError) setTermsError(null);
    setToggleCheck(!toggleCheck);
  };
  const handleCloseTerms = (): void => setTermsOpen(false);
  const handleSuccessClose = (): void => {
    setSuccessModal(false);
    setFormData(defaultFormData);
  }

  const handleSubmit = (): void => {
    const validation = handleApplicationValidation(formData);
    const isError = Object.values(validation).find(item => item);
    if (isError) return setFormError(validation);
    if (!toggleCheck) return setTermsError("Accept our terms before proceeding");
    registerGuest(formData);
  };

  useEffect(() => {
    if (error) {
      if (JSON.parse(JSON.stringify(error))?.data?.errors?.message) {
        dispatch(SetToast({
          status: "warn",
          title: "Application Submission",
          message: JSON.parse(JSON.stringify(error))?.data?.errors?.message
        }));
      } else dispatch(SetToast(handleErrResponse(error as FetchBaseQueryError)));
    }
    if (data) {
      dispatch(SetToast({
        status: "success",
        title: "Application Submission",
        message: "Application submitted successfully"
      }));
      setSuccessModal(true);
    }
  }, [error, data, dispatch]);

  return (
    <S.ApplicationBackground isMobile={isMobile}>
      <S.ApplicationContent isMobile={isMobile}>
        <S.ApplicationHeader>REGISTER HERE</S.ApplicationHeader>
        <S.ApplicationForm onSubmit={handleSubmit}>
          <InputField type="text" name="first_name" value={formData.first_name} placeholder="First Name" errorMessage={formError.first_name} handleChange={handleChange}  />
          <InputField type="text" name="last_name" value={formData.last_name} placeholder="Last Name" errorMessage={formError.last_name} handleChange={handleChange}  />
          <InputField type="email" name="email" value={formData.email} placeholder="Email Address" errorMessage={formError.email} handleChange={handleChange}  />
          <InputField type="tel" name="phone_number" value={formData.phone_number} placeholder="Contact Number" errorMessage={formError.phone_number} handleChange={handleChange} />
          <div>
            <S.Terms>
              <S.CheckWrapper>
                <S.AppCheckBox type="checkbox" checked={toggleCheck} onChange={handleCheckBox} />
                <S.BoxMark $toggleCheck={toggleCheck} onClick={handleCheckBox}></S.BoxMark>
              </S.CheckWrapper>
              <S.Statement>By Submitting this form, you agree to accept our <S.TermSpan onClick={handleTermsClick}>Terms and Conditions</S.TermSpan></S.Statement>
            </S.Terms>
            {termsError && (<S.TermsError>{termsError}</S.TermsError>)}
          </div>
          <Button loading={isLoading} disabled={isLoading} handleClick={handleSubmit}>Submit</Button>
        </S.ApplicationForm>
      </S.ApplicationContent>

      {termsOpen && (
        <TermsCondition close={handleCloseTerms} />
      )}

      {successModal && (
        <SuccessApplication close={handleSuccessClose} title="Application Status" />
      )}
    </S.ApplicationBackground>
  )
}

export default ApplicationComponent;
