import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SetToast, useAppDispatch, useAppSelector } from "../../redux";
import { ErrResponseType } from "../../utils";
import * as S from "./toast.styled";

export const Toast: React.FC = () => {
  const dispatch = useAppDispatch();
  const { toastState } = useAppSelector(state => state.appstate);

  const customToast = ({
    message,
    status,
    title,
  }: ErrResponseType) => {
    if (status !== "") return toast[status](<ToastContent title={title.toLowerCase()} message={message} />, { theme: "colored" })
    return toast(message);
  };

  useEffect(() => {
    if (toastState.message.length) {
      customToast({ message: toastState.message, status: toastState.status, title: toastState.title });
      setTimeout(() => {
        dispatch(SetToast({ message: "", status: "", title: "" }));
      }, 3000);
    }
  }, [dispatch, toastState]);

  return (
    <S.Wrapper>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </S.Wrapper>
  );
};

export default Toast;

export const ToastContent: React.FC<Omit<ErrResponseType, "status">> = ({ title, message }) => {
  return (
    <S.ToastWrapper>
      <S.ToastTitle>{title}</S.ToastTitle>
      <S.ToastMessage>{message}</S.ToastMessage>
    </S.ToastWrapper>
  )
};