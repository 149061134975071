import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {
  IFetchAvailability,
  RegisterGuestType,
  AdminLoginType,
  IGuestDetailsResponse,
  IApproveGuestPayload,
  IApproveGuestResponse,
  IAdminDashboard,
  IAuthResponse,
  ISeatResponse,
  ISeatPayload,
  IScannerScanned,
} from "../typings";

const baseUrl = process.env.REACT_APP_BASE_URL as string;
const tokenKey = process.env.REACT_APP_AUTH_TOKEN as string;

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(tokenKey);
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (build) => ({
    fetchAvailability: build.query<IFetchAvailability, void>({
      query: () => "/",
    }),
    registerGuest: build.mutation<
      RegisterGuestType,
      Partial<RegisterGuestType>
    >({
      query: (body) => ({
        url: `/guest/register`,
        method: "POST",
        body,
      }),
    }),
    adminLogin: build.mutation<IAuthResponse, AdminLoginType>({
      query: (body) => ({
        url: `/auth/login`,
        method: "POST",
        body,
      }),
    }),
    guestDetails: build.query<IGuestDetailsResponse, string>({
      query: (userId) => ({
        url: `/scanner/guest/${userId}`,
        method: "GET",
      }),
    }),
    approveGuest: build.mutation<IApproveGuestResponse, IApproveGuestPayload>({
      query: (body) => ({
        url: `/scanner/guest/approve`,
        method: "POST",
        body,
      }),
    }),
    adminDashboard: build.query<IAdminDashboard, void>({
      query: () => ({
        url: `/admin/data`,
        method: "GET",
      }),
    }),
    closeRegistration: build.mutation<ISeatResponse, void>({
      query: () => ({
        url: `admin/settings/close-registration`,
        method: "PUT",
      }),
    }),
    openRegistration: build.mutation<ISeatResponse, void>({
      query: () => ({
        url: `admin/settings/open-registration`,
        method: "PUT",
      }),
    }),
    slotAdjustment: build.mutation<ISeatResponse, ISeatPayload>({
      query: (body) => ({
        url: `admin/settings/add-seats`,
        method: "PUT",
        body,
      }),
    }),
    scannerDashboard: build.query<IScannerScanned, void>({
      query: () => ({
        url: `/scanner/scanned`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useFetchAvailabilityQuery,
  useGuestDetailsQuery,
  useScannerDashboardQuery,
  useAdminDashboardQuery,

  useRegisterGuestMutation,
  useAdminLoginMutation,
  useApproveGuestMutation,
  useCloseRegistrationMutation,
  useOpenRegistrationMutation,
  useSlotAdjustmentMutation,
} = appApi;
