import { FC, useState } from "react";
import { InputField, ModalComponent } from "../../common";
import { UnknownType, validateEmail } from "../../utils";
import * as S from "./admin.styled";

interface IResendQRCode {
  close: () => void;
  title: string;
}

const ResendQRCode: FC<IResendQRCode> = ({ close, title }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<null | string>(null);

  const handleChange = (e: UnknownType): void => {
    setEmailError(null);
    setEmail(e.target.value);
  }

  const handleSubmit = (e: UnknownType): void => {
    e.preventDeafult();
    const fieldError = validateEmail(email);
    if (fieldError) return setEmailError(fieldError);
    console.log(email);
  }

  return (
    <ModalComponent title={title} close={close}>
      <S.ModalForm onSubmit={handleSubmit}>
        <InputField type="email" name="email" value={email} placeholder="Email Address" errorMessage={emailError} handleChange={handleChange} />
        <S.SubmitButton type="submit">Submit</S.SubmitButton>
      </S.ModalForm>
    </ModalComponent>
  )
}

export default ResendQRCode;