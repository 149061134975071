import { FlattenSimpleInterpolation } from "styled-components";
import { GenericErrorType } from "./typings";

export type StyledProps = FlattenSimpleInterpolation | string | undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UnknownType = any;

export const defaultFormData = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
};

export const defaultFormError: GenericErrorType = {
  first_name: null,
  last_name: null,
  email: null,
  phone_number: null,
};

export const defaultLoginData = {
  email: "",
  pin: "",
};

export const defaultLoginError: GenericErrorType = {
  email: null,
  pin: null,
};

export const apiErrorType: {[key: string]: string} = {
  FORM_VALIDATION: "Incorrect input(s) supplied",
}

export const statusCodes = {
  serverRegEx: /^5\d{2}$/,
  clientRegEx: /^4\d{2}$/,
  success: /^2\d{2}$/,
};