import { FC, useState, useEffect } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useNavigate } from "react-router-dom";
import { Button, InputField } from "../../common";
import { SetToast, useAppDispatch, useAdminLoginMutation, setUser } from "../../redux";
import AuthLayout from "./AuthLayout";
import {
  defaultLoginData,
  defaultLoginError,
  UnknownType,
  handleLoginValidation,
  handleErrResponse,
  extractTokenData,
} from "../../utils";
import * as S from "./auth.styled";


export const LoginComponent: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [adminLogin, { data, isLoading, error }] = useAdminLoginMutation();
  const [formData, setFormData] = useState(defaultLoginData);
  const [formError, setFormError] = useState(defaultLoginError);

  const updateState = (key: string, value: string): void => {
    if (formError[key]) setFormError({ ...formError, [key]: null });
    setFormData({ ...formData, [key]: value });
  };

  const handleChange = (e: UnknownType): void => {
    e.preventDefault();
    const { name, value } = e.target;
    updateState(name, value);
  };

  const handleSubmit = (): void => {
    const validation = handleLoginValidation(formData);
    const isError = Object.values(validation).find(item => item);
    if (isError) return setFormError(validation);
    adminLogin(formData);
  };

  useEffect(() => {
    if (error) dispatch(SetToast(handleErrResponse(error as FetchBaseQueryError)));
    if (data) {
      const tokenKey = process.env.REACT_APP_AUTH_TOKEN as string;
      const { access_token } = data?.data || {};
      localStorage.setItem(tokenKey, access_token);
      const tokenizedData = extractTokenData();
      if (tokenizedData) {
        const { id, role, email } = tokenizedData;
        dispatch(setUser({ id, role, email, isLoggedIn: true }));
        if (role?.toUpperCase() === "ADMIN") navigate("/admin");
        if (role?.toUpperCase() === "SCANNER") navigate("/scanner");
      };
      dispatch(SetToast({ status: "success", title: "Login", message: "Login successfully" }));
    };
  }, [error, data, dispatch, navigate]);
  
  return (
    <AuthLayout>
      <S.LoginWrapper>
        <S.AuthHeader>Login</S.AuthHeader>
        <S.AuthForm onSubmit={handleSubmit}>
          <InputField label="Email" type="email" name="email" value={formData.email} placeholder="Enter your email" errorMessage={formError.email} handleChange={handleChange}  />
          <InputField label="Pin" type="password" name="pin" value={formData.pin} placeholder="Enter your pin" errorMessage={formError.pin} handleChange={handleChange} />
          
          <Button loading={isLoading} disabled={isLoading} handleClick={handleSubmit}>Submit</Button>
        </S.AuthForm>
      </S.LoginWrapper>
    </AuthLayout>
  )
}

export default LoginComponent;