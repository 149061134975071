import React from "react";
import Spinner from "../spinner";
import * as S from "./button.styled";

interface IButton {
  loading?: boolean;
  disabled?: boolean;
  handleClick?: () => void;
  children: string | JSX.Element;
  type?: "button" | "submit" | "reset";
}

export const Button: React.FC<IButton> = ({ loading, disabled, children, handleClick, type }) => {
  const handler = (e: any) => {
    e.preventDefault();
    handleClick?.();
  };
  return (
    <S.SubmitButton type={type} disabled={disabled} onClick={handler}>
      {children}
      {loading && (
        <Spinner />
    )}
    </S.SubmitButton>
  )
}

export default Button;