import { FC, useState, useEffect } from "react";
import * as S from "./auth.styled";
import { getIsMobile } from "../../utils";

interface IAuthComponent {
  children: JSX.Element;
}

export const AuthLayout: FC<IAuthComponent> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(getIsMobile());

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const handleWindowResize = () => setIsMobile(getIsMobile());
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <S.AuthBackground isMobile={isMobile}>
      <S.AuthContent isMobile={isMobile}>
        {children}
      </S.AuthContent>
    </S.AuthBackground>
  )
}

export default AuthLayout;
