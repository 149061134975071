import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { AppState, IUser, IToastState, IAdminDashboard, ISeatResponse, IScannerScanned } from "../typings";

export const initialState: AppState = {
  toastState: { title: "", message: "", status: "" },
  canRegister: true,
  user: { role: null, email: null, id: null, isLoggedIn: false },
  admin: { availableSlot: 0, totalScanned: 0, totalRegistered: 0, closed: false },
  scanner: { successfulScanned: 0, },
  openScanner: false,
};

export const appState = createSlice({
  name: "appstate",
  initialState,
  reducers: {
    SetToast: (
      state,
      action: PayloadAction<IToastState>
    ) => {
      state.toastState = action.payload;
    },
    setCanRegister: (state, action: PayloadAction<boolean>) => {
      state.canRegister = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    setAdmin: (state, action: PayloadAction<IAdminDashboard>) => {
      const { guests, attendees, settings } = action.payload.data;
      state.admin = {
        availableSlot: settings.seats || 0,
        totalScanned: attendees || 0,
        totalRegistered: guests || 0,
        closed: settings.closed || false,
      }
    },
    setScanner: (state, action: PayloadAction<IScannerScanned>) => {
      const { total } = action.payload.data;
      state.scanner = { successfulScanned: total, };
    },
    setOpenScanner: (state, action: PayloadAction<boolean>) => {
      state.openScanner = action.payload;
    },
    setToggleRegistration: (state, action: PayloadAction<ISeatResponse>) => {
      state.admin = { ...state.admin, closed: action.payload.data.closed };
    },
    setSlots: (state, action: PayloadAction<number>) => {
      state.admin = { ...state.admin, availableSlot: action.payload };
    },
    setLogout: (state, action: PayloadAction<AppState>) => {
      state = action.payload;
    }
  },
});

export const {
  SetToast,
  setCanRegister,
  setUser,
  setAdmin,
  setScanner,
  setOpenScanner,
  setToggleRegistration,
  setSlots,
  setLogout,
} = appState.actions;

export const selectAppState = (state: RootState) => state.appstate;

export default appState.reducer;
